import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Products } from '../../types';
import Styles from './Carousel.module.css';
import { usePageContext } from '../../Context';

export default function CarouselCard(props: Products) {
  const { getTag } = usePageContext();
  const navigate = useNavigate();
  const {
    uuid,
    quantity,
    manufacturer,
    model,
    prices,
    featuredPicture
  } = props;
  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      onClick={() => navigate(`/product/${uuid}`)}
      className={Styles.card}
    >
      <Typography variant='h5'>{`${quantity}x ${manufacturer} ${model}`}</Typography>
      <img className={Styles.productImage} src={`data:image/png;base64,${featuredPicture}`} alt='product' />
      <div className={Styles.priceDiv}>
        <Typography style={{ margin: 0 }}>
          {`${getTag('startsFrom')} ${prices[0].price}€`}
        </Typography>
      </div>
    </div>
  );
}
