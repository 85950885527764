import React, { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import Styles from './Carousel.module.css';
import CarouselCard from './CarouselCard';
import { Products } from '../../types';
import { usePageContext } from '../../Context';
import { getFeaturedProducts, getProducts } from '../../helpers/BackendHelpers';

export default function Carousel(props: { featured: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [featuredProducts, setFeaturedProducts] = useState<Products[]>([]);
  const box = document.querySelector('#productContainer');
  const { setLoading, snackBarEvent, getTag } = usePageContext();

  const fetchPictures = async () => {
    setLoading(true);
    const data = props.featured ? await getFeaturedProducts() : await getProducts();
    if (!data) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    } else {
      setFeaturedProducts(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPictures();
  }, []);

  const btnPressPrev = () => {
    if (box) {
      const width = box.clientWidth;
      box.scrollLeft -= width;
    }
  };

  const btnPressNext = () => {
    if (box) {
      const width = box.clientWidth;
      box.scrollLeft += width;
    }
  };

  return (
    <div className={Styles.productCarousel}>
      {(isMobile || featuredProducts?.length > 2) && (
        <>
          <ArrowCircleLeftOutlinedIcon className={Styles.preBtn} onClick={btnPressPrev} />
          <ArrowCircleRightOutlinedIcon className={Styles.nextBtn} onClick={btnPressNext} />
        </>
      )}
      <div id='productContainer' className={Styles.productContainer}>
        {featuredProducts?.map((product) => (
          <CarouselCard {...product} />
        ))}
      </div>
    </div>
  );
}
