import React from 'react';
import { Typography, Grid } from '@mui/material';
import Styles from './Home.module.css';
import Carousel from './Carousel';
import { usePageContext } from '../../Context';

export default function Home() {
  const { getTag } = usePageContext();

  return (
    <>
      <div className={Styles.imageDiv}>
        <img src='/images/homeBanner.jpg' alt='Jetski' className={Styles.topImage} />
        <div className={Styles.welcomeTxt}>
          <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
            {getTag('rentfromus')}
          </Typography>
          <Typography>
            {getTag('homeparagraph')}
          </Typography>
        </div>
      </div>
      <Carousel featured />
      <div className={Styles.marketingImageDiv}>
        <img src='/images/sunset.jpg' alt='sunset' className={Styles.marketingImage} />
        <div className={Styles.svgDiv}>
          <img
            src='/images/full.png'
            aria-label='full'
            className={Styles.marketingSvg}
          />
          <img
            src='/images/service.png'
            aria-label='full'
            className={Styles.marketingSvg}
          />
        </div>
      </div>
      <Grid container spacing={0} className={Styles.videoGrid}>
        <Grid className={Styles.videoItem} item xs={12} lg={4}>
          {/* eslint-disable jsx-a11y/media-has-caption */}
          <video className={Styles.video} controls>
            <source src='/videos/step1.m4v' type='video/mp4' />
          </video>
        </Grid>
        <Grid className={Styles.videoItem} item xs={12} lg={4}>
          <video className={Styles.video} controls>
            <source src='/videos/step2.m4v' type='video/mp4' />
          </video>
        </Grid>
        <Grid className={Styles.videoItem} item xs={12} lg={4}>
          <video className={Styles.video} controls>
            <source src='/videos/step3.m4v' type='video/mp4' />
          </video>
        </Grid>
      </Grid>
    </>
  );
}
