import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Styles from './About.module.css';
import { usePageContext } from '../../Context';
import { getAboutText } from '../../helpers/BackendHelpers';

export default function About() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale
  } = usePageContext();
  const [aboutText, setAboutText] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    const text = await getAboutText();
    if (!text) {
      snackBarEvent('error', getTag('unabletofetchmarkdowntext'));
    } else {
      setAboutText(text);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={Styles.imageDiv}>
        <img src='/images/aboutBanner.jpg' alt='' className={Styles.banner} />
      </div>
      <div className={Styles.textDiv}>
        <ReactMarkdown>{aboutText?.[locale]}</ReactMarkdown>
      </div>
    </>
  );
}
