import { Link } from 'react-router-dom';
import React, { useState, MouseEvent } from 'react';
import {
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Pages } from '../../types';
import { usePageContext } from '../../Context';

interface PropsInterface {
  pages?: Pages[];
  navbarFontWeight: number;
  navbarFontSize: number;
}

export default function DrawerComponent(props: PropsInterface) {
  const { pages, navbarFontWeight, navbarFontSize } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getTag } = usePageContext();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonType = (page: { name: string, path: string }) => {
    if (page.name === 'book') {
      return (
        <MenuItem
          sx={{
            fontWeight: navbarFontWeight,
            fontSize: navbarFontSize
          }}
          onClick={() => window.location.assign('https://booking.codebite.fi/hyyre/')}
        >
          {getTag(page.name)}
        </MenuItem>
      );
      // eslint-disable-next-line
    } else {
      return (
        <MenuItem
          sx={{
            fontWeight: navbarFontWeight,
            fontSize: navbarFontSize
          }}
          onClick={handleClose}
          component={Link}
          to={page.path}
        >
          {getTag(page.name)}
        </MenuItem>
      );
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {pages?.map((page) => (buttonType(page)))}
      </Menu>
      <IconButton
        style={{ color: '#fff' }}
        onClick={handleClick}
      >
        <MenuIcon sx={{ fontWeight: navbarFontWeight, fontSize: 40 }} />
      </IconButton>
    </>
  );
}
