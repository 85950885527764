import { Outlet, Link } from 'react-router-dom';
import React from 'react';
import {
  useMediaQuery,
  Box,
  Grid,
  useTheme,
  AppBar,
  Button
} from '@mui/material';
import Styles from './Navbar.module.css';
import DrawerComponent from './Drawer';
import LanguageMenu from './LanguageMenu';
import { usePageContext } from '../../Context';

const pages = [{
  name: 'home',
  path: '/'
},
{
  name: 'book',
  path: 'https://booking.codebite.fi/hyyre/'
},
{
  name: 'jetskis',
  path: '/products'
},
{
  name: 'aboutus',
  path: '/about'
},
{
  name: 'findus',
  path: '/find'
}];

export default function Layout() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { getTag } = usePageContext();

  const navbarFontSize = 20;
  const navbarFontWeight = 700;

  const buttonType = (page: { name: string, path: string }) => {
    if (page.name === 'book') {
      return (
        <Button
          onClick={() => window.location.assign('https://booking.codebite.fi/hyyre/')}
          sx={{
            fontWeight: 700,
            fontSize: 20,
            color: 'white',
            flexGrow: 1,
            display: 'block'
          }}
        >
          {getTag(page.name)}
        </Button>
      );
      // eslint-disable-next-line
    } else {
      return (
        <Button
          key={page.name}
          component={Link}
          to={page.path}
          sx={{
            fontWeight: 700,
            fontSize: 20,
            color: 'white',
            flexGrow: 1,
            display: 'block'
          }}
        >
          {getTag(page.name)}
        </Button>
      );
    }
  };

  return (
    <>
      <AppBar position='static'>
        <Grid
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          className={Styles.navbar}
          sx={{ backgroundColor: 'primary.main' }}
        >
          <div className={Styles.leftSide}>
            <LanguageMenu navbarFontWeight={navbarFontWeight} navbarFontSize={navbarFontSize} />
          </div>
          <div className={Styles.mid}>
            <Box
              component='img'
              src='/logo.svg'
              alt='logo'
              className={Styles.hyyreLogo}
            />
          </div>
          <div className={Styles.rightSide}>
            {isMobile ? (
              <DrawerComponent navbarFontWeight={navbarFontWeight} navbarFontSize={navbarFontSize} pages={pages} />
            ) : (
              pages?.map((page) => (buttonType(page)))
            )}
          </div>
        </Grid>
      </AppBar>
      <Outlet />
    </>
  );
}
