import { get } from './BackendService';

export function getProduct(uuid: string | undefined) {
  if (uuid) {
    return get(`products/${uuid}`);
  }
  return '';
}

export function getFeaturedProducts() {
  return get('products/featured');
}

export function getProducts() {
  return get('products');
}

export function getRentalConditions() {
  return get('markdowntext/rentalconditions');
}

export function getAboutText() {
  return get('markdowntext/about');
}

export function getFindText() {
  return get('markdowntext/find');
}

export function getProductsText() {
  return get('markdowntext/products');
}
