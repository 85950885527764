import axios, { AxiosResponse } from 'axios';
import config from './config.json';

interface AxiosData {
  data: any;
}

const baseURL = config.BACKEND_BASE;

export const get = async (endPoint: string) => (
  axios.get(`${baseURL}/${endPoint}`).then((res) => res?.data)
);

export async function post(endPoint: string, body?: any): Promise<AxiosResponse<AxiosData>> {
  return axios.post<AxiosData>(`${baseURL}/${endPoint}`, body, { withCredentials: true });
}
