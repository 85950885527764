import * as React from 'react';
import {
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import { usePageContext } from '../../Context';

interface PropsInterface {
  navbarFontWeight: number;
  navbarFontSize: number;
}

export default function BasicMenu(props: PropsInterface) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { changeLang, locale } = usePageContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    setAnchorEl(null);
    if ((event.target as HTMLInputElement).id) {
      changeLang((event.target as HTMLInputElement).id);
    }
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: '#fff', fontWeight: props.navbarFontWeight, fontSize: props.navbarFontSize }}
      >
        {locale}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem id='sv' sx={{ fontWeight: props.navbarFontWeight, fontSize: props.navbarFontSize }} onClick={handleClose}>sv</MenuItem>
        <MenuItem id='fi' sx={{ fontWeight: props.navbarFontWeight, fontSize: props.navbarFontSize }} onClick={handleClose}>fi</MenuItem>
      </Menu>
    </div>
  );
}
