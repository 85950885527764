import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Styles from './Footer.module.css';
import { usePageContext } from '../../Context';

export default function Home() {
  const { getTag } = usePageContext();

  return (
    <>
      <img src='/images/footerImage.png' alt='footer' className={Styles.footerImage} />
      <div className={Styles.footerDiv}>
        <div className={Styles.leftDiv}>
          <Typography variant='h6'>
            {getTag('hyyre')}
          </Typography>
          <Typography
            variant='h6'
            sx={{ cursor: 'pointer', color: 'white' }}
            component={Link}
            to='rental-conditions'
          >
            {getTag('rentalconditions')}
          </Typography>
        </div>
        <div className={Styles.rightDiv}>
          <Typography variant='h6'>
            {getTag('tyrecenter')}
          </Typography>
          <Typography variant='h6'>
            {getTag('adress')}
          </Typography>
          <Typography variant='h6'>
            {getTag('postal')}
          </Typography>
        </div>
      </div>
    </>
  );
}
