import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import theme from './components/Theme';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Products from './components/products/Products';
import About from './components/about/About';
import Find from './components/find/Find';
import RentalConditions from './components/rentalConditions/RentalConditions';
import ProductInfo from './components/products/productInfo/ProductInfo';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='products' element={<Products />} />
          <Route path='product/:uuid' element={<ProductInfo />} />
          <Route path='about' element={<About />} />
          <Route path='find' element={<Find />} />
          <Route path='rental-conditions' element={<RentalConditions />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
