import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from '@mui/material/Button';
import Styles from './Find.module.css';
import { usePageContext } from '../../Context';
import { getFindText } from '../../helpers/BackendHelpers';

export default function Find() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale
  } = usePageContext();
  const [findText, setFindText] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    const text = await getFindText();
    if (!text) {
      snackBarEvent('error', getTag('unabletofetchmarkdowntext'));
    } else {
      setFindText(text);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showBiggerMap = () => {
    // eslint-disable-next-line max-len
    window.open('https://maps.google.fi/maps?f=q&source=embed&hl=sv&geocode=&q=Krokv%C3%A4gen+144,+Larsmo&aq=0&oq=Krokv%C3%A4gen+144&sll=51.572476,5.10914&sspn=0.012603,0.026157&ie=UTF8&hq=&hnear=Krokv%C3%A4gen+144,+Larsmo&t=m&z=13&iwloc=A');
  };

  return (
    <div className={Styles.textDiv}>
      <ReactMarkdown>{findText?.[locale]}</ReactMarkdown>
      <div className={Styles.mapWrapper}>
        <div className={Styles.leftMap}>
          <Button
            onClick={showBiggerMap}
            variant='contained'
          >
            {getTag('showbiggermap')}
          </Button>
        </div>
        <div className={Styles.rightMap}>
          <iframe
            title='Krokvägen 144'
            width='100%'
            height='500'
            id='gmap_canvas'
            src='https://maps.google.com/maps?q=Krokv%C3%A4gen%20144%2C%2068560%20Eugmo&t=&z=10&ie=UTF8&iwloc=&output=embed'
            frameBorder='0'
            scrolling='no'
          />
        </div>
      </div>
    </div>
  );
}
