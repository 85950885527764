import React from 'react';
import ReactMarkdown from 'react-markdown';
import Grid from '@mui/material/Grid';
import { Product } from '../../../../types';
import styles from './InfoView.module.css';
import { usePageContext } from '../../../../Context';
import YoutubeEmbed from './YoutubeEmbed';

interface propsInterface {
  product: Product;
}

interface language {
  sv: string,
  fi: string,
}

export default function InfoView(props: propsInterface) {
  const { product } = props;
  const { locale } = usePageContext();

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.itemLeft} item xs={12} lg={4}>
        <ReactMarkdown>{product.info[locale as keyof language]}</ReactMarkdown>
      </Grid>
      <Grid className={styles.itemMid} item xs={12} lg={4}>
        <ReactMarkdown>{product.technical[locale as keyof language]}</ReactMarkdown>
      </Grid>
      <Grid className={styles.itemRight} item xs={12} lg={4}>
        <YoutubeEmbed embedId={product.video.split('=')[1]} />
      </Grid>
    </Grid>
  );
}
