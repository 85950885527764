import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import styles from './ProductInfo.module.css';
import { Product } from '../../../types';
import { usePageContext } from '../../../Context';
import { getProduct } from '../../../helpers/BackendHelpers';
import InfoView from './infoView/InfoView';

export default function Products() {
  const { setLoading, snackBarEvent, getTag } = usePageContext();
  const [item, setItem] = useState<Product>();
  const { uuid } = useParams();

  const fetchPictures = async () => {
    setLoading(true);
    const data = await getProduct(uuid!);
    if (!data) {
      snackBarEvent('error', getTag('unabletofetchproducts'));
    } else {
      setItem(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPictures();
  }, []);

  return (
    item ? (
      <>
        <div className={styles.imageDiv}>
          <img src={`data:image/png;base64,${item?.pictures.background.picture}`} alt='img' className={styles.topImage} />
        </div>
        <div className={styles.titleWrapper}>
          <div className={styles.titleBox}>
            <Typography sx={{ fontSize: '1.9rem', fontWeight: 'bold' }}>
              {`${item?.manufacturer} ${item?.model}`}
            </Typography>
          </div>
          <div className={styles.mobileFlex}>
            <Button
              className={styles.bookButton}
              variant='contained'
              onClick={() => window.location.assign('https://booking.codebite.fi/hyyre/')}
            >
              {getTag('goToBookingPage')}
            </Button>
          </div>
        </div>
        <InfoView product={item} />
      </>
    ) : (
      <div className={styles.noItemDiv}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{getTag('unableToFindProduct')}</Typography>
      </div>
    ));
}
