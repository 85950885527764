import { createTheme } from '@mui/material/styles';

const fallbackColors = {
  primaryColorMain: '#279bab',
  primaryColorLight: '#efeeeb',
  primaryColorSecondary: '#bad85f'
};

export default createTheme({
  palette: {
    primary: {
      main: fallbackColors.primaryColorMain,
      light: fallbackColors.primaryColorLight
    },
    secondary: {
      main: fallbackColors.primaryColorSecondary
    }
  }
});
