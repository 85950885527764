import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown';
import Styles from './Products.module.css';
import { usePageContext } from '../../Context';
import { getProductsText } from '../../helpers/BackendHelpers';
import Carousel from '../home/Carousel';

export default function Products() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale
  } = usePageContext();
  const [productsText, setProductsText] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    const text = await getProductsText();
    if (!text) {
      snackBarEvent('error', getTag('unabletofetchmarkdowntext'));
    } else {
      setProductsText(text);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={Styles.imageDiv}>
        <img src='/images/productBanner.jpg' alt='Jetski' className={Styles.banner} />
      </div>
      <div className={Styles.textDiv}>
        <ReactMarkdown>{productsText?.[locale]}</ReactMarkdown>
        <Button
          component={Link}
          to='/rental-conditions'
          variant='contained'
        >
          {getTag('readrentalconditions')}
        </Button>
        <h1>{getTag('werent')}</h1>
      </div>
      <Carousel featured={false} />
    </>
  );
}
