import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Styles from './RentalConditions.module.css';
import { usePageContext } from '../../Context';
import { getRentalConditions } from '../../helpers/BackendHelpers';

export default function RentalConditions() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale
  } = usePageContext();
  const [rentalConditions, setRentalConditions] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    const text = await getRentalConditions();
    if (!text) {
      snackBarEvent('error', getTag('unabletofetchmarkdowntext'));
    } else {
      setRentalConditions(text);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={Styles.imageDiv}>
        <img src='/images/rentalConditionsBanner.jpg' alt='Jetski' className={Styles.banner} />
      </div>
      <div className={Styles.textDiv}>
        <ReactMarkdown>{rentalConditions?.[locale]}</ReactMarkdown>
      </div>
    </>
  );
}
