import React from 'react';

interface propsInterface {
  embedId: string;
}

export default function YoutubeEmbed(props: propsInterface) {
  return (
    <iframe
      width='100%'
      height='100%'
      src={`https://www.youtube.com/embed/${props.embedId}`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='Embedded youtube'
    />
  );
}
