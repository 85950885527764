import React, { useState, useEffect, useMemo } from 'react';
import {
  Snackbar,
  Backdrop,
  CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import App from './App';
import config from './helpers/config.json';
import { Context } from './Context';
import { snackSeverity } from './types';
import { get } from './helpers/BackendService';

export default function ContextProvider() {
  const [loading, setLoading] = useState<boolean>(false);
  const [severity, setSeverity] = useState<snackSeverity>('error');
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const getLocale = () => {
    const lang = localStorage.getItem('lang');
    if (lang && ['sv', 'fi'].includes(lang)) {
      return lang;
    }
    return config.DEFAULT_LANG;
  };
  const [locale, setLocale] = useState<string>(getLocale());
  const [langTags, setLangTags] = useState<any>({});

  const getLangTags = () => {
    setLoading(true);
    get('langTags').then(
      (res) => {
        setLangTags(res);
        setLoading(false);
      },
      () => {
        snackBarEvent('error', 'Unable to fetch language');
        setLoading(false);
      }
    );
  };

  const getTag = (shorthand: string) => langTags?.[shorthand]?.[locale] || '';

  const changeLang = (choice: string) => {
    localStorage.setItem('lang', choice);
    setLocale(choice);
  };

  const snackBarEvent = (s: snackSeverity, m: string) => {
    setSeverity(s);
    setSnackMessage(m);
    setOpen(true);
  };

  const closeSnackBar = (event?: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getLangTags();
  }, []);

  const value = useMemo(() => ({
    setLoading,
    snackBarEvent,
    getTag,
    changeLang,
    locale
  }), [loading, locale, langTags, open]);

  return (
    <>
      <Context.Provider value={value}>
        <App />
      </Context.Provider>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' } as any} open={open} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert onClose={closeSnackBar} elevation={2} variant='filled' severity={severity}>
          {snackMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
